import { render, staticRenderFns } from "./history.vue?vue&type=template&id=d1d69580&scoped=true&"
import script from "./history.vue?vue&type=script&lang=js&"
export * from "./history.vue?vue&type=script&lang=js&"
import style0 from "./history.vue?vue&type=style&index=0&id=d1d69580&scoped=true&lang=css&"
import style1 from "./assets/index.css?vue&type=style&index=1&id=d1d69580&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1d69580",
  null
  
)

export default component.exports