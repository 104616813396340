<template>
  <div class="main">
    <!-- -->
    <van-nav-bar
      title="历史检查"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="list">
      <!-- <van-button type="primary" style="position: fixed; bottom: 80px;right: 20px; width: 50px;height: 50px;border-radius: 50%;" icon="plus" @click="$router.push('/addTask')"></van-button> -->
      <div v-if="tasklist && tasklist.length == 0">
        <van-empty description="暂无数据" />
      </div>
      <div v-else class="item" v-for="(x,index) in tasklist" :key="index">
        <div style="height: 20px"></div>
        <van-row>
          <van-col span="18"><div class="name">{{x.storeName}}</div></van-col>
          <van-col span="6">
            <div class="type ">{{ctype(x.type)}}</div>
          </van-col>
        </van-row>
        <div class="biao">
          {{x.remark}}
        </div>
        <div style="margin-top:20px "></div>
        <div class="xiafa"><span @click="detail(x.id)">查 看</span></div>
        <!-- <div class="xiafa" v-if="userType==50"><span plain type="info" @click="xiafa(x.id)">下发检查</span></div> -->
        <!-- <div class="xiafa" v-if="userType==40"><span plain type="info" @click="begin(x.id,x.regulatoryTaskId)">开始检查</span></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getTask, getType } from '@/api/get'
import Vue from 'vue';
import { NavBar, Button, col, row, Image, Empty } from 'vant'
Vue.use(NavBar)
Vue.use(Button)
Vue.use(Image)
Vue.use(Empty)
export default {
  name: 'RegulatoryH5Today',
  components:{
    [Button.name]: Button,
    'van-col': col,
    'van-row': row,
  },
  data() {
    return {
      tasklist: [],
      userType: '',
    };
  },

  mounted() {
    this.userType=localStorage.userType
    this.taskList()
  },

  methods: {
    onClickLeft() {
      this.$router.push('/')
    },
    xiafa(id) {
      this.$router.push({ path: '/xiafa', query: {id:id}})
    },
    begin(id,regulatoryTaskId) {
      this.$router.push({ path: '/begin', query: {id:id,taskId:regulatoryTaskId}})
    },
    ctype(t){
      return getType(t)
    },
    detail(id){
      this.$router.push({ path: '/detail', query: {id:id}})
    },
    taskList(){
      let post={
        page:1,
        count:100,
        isToday:1 ,
        status:30
      }
      this.tasklist = null
      getTask(post).then(res => {
        if (res.data.code === 200){
          this.tasklist=res.data.data.list
        }
      })
    },
  },
};
</script>

<style scoped>
.row{display: flex;justify-content: space-between;line-height: 200%}
.title{font-size: 16px;font-weight: bolder}
/*.taskList{ height: calc(100vh - 240px);overflow: auto}*/
.taskItem{margin-bottom: 5px;background-color: white;padding: 10px 10px}
.level {
  /* min-height: 30px; */
  /* line-height: 50px; */
  margin-bottom: 5px !important;
}
.item-head {
  /* min-height: 30px; */
  padding: 15px 0;
}
.item-body {
  /* height: 70px; */
  /* line-height: 70px; */
  padding: 20px 0;
}
.item-h .van-col {
  display: flex;
  align-items: center;
}
.item {
  background-color: #ffffff;
  margin: 5px;
}
</style>

<style scoped lang="css" src="./assets/index.css" />
