<template>
  <div>
    <!-- -->
    <van-nav-bar
      title="监管所任务"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="list">
      <van-list v-if="AllTask && AllTask.length > 0">
        <div class="taskList">
          <div class="taskItem" v-for="x in AllTask" :key="x.id">
            <div class="row">
              <div class="title">{{x.storeName}}</div>
              <div>{{ctype(x.type)}}</div>
            </div>
            <div class="row">
              <div class="">联系人：{{x.contact}}</div>
              <div>电话：{{x.phone}}</div>
            </div>
            <div class="row">
              <div class="">检查结果：{{x.totalScore}}</div>
            </div>
            <div class="row">
              <div>备注：{{x.remark}}</div>
            </div>
          </div>
        </div>
      </van-list>
      <div v-else>
        <van-empty description="暂无数据" />
      </div>
    </div>
  </div>
</template>

<script>
import {  taskAll, getType } from '@/api/get'
import Vue from 'vue';
import { NavBar, Button, List, Image, Empty } from 'vant'
Vue.use(NavBar)
Vue.use(Button)
Vue.use(List)
Vue.use(Image)
Vue.use(Empty)
export default {
  name: 'RegulatoryH5Task',

  data() {
    return {
      AllTask: []
    };
  },

  created() {
    this.taskAll()
  },

  methods: {
    onClickLeft() {
      this.$router.push('/')
    },
    async taskAll(){
      this.AllTask = null
      let rs=await taskAll()
      if(rs.data.code==200){
        this.AllTask=rs.data.data.list
      }
    },
    ctype(t){
      return getType(t)
    },
  },
};
</script>


<style scoped>
.row{display: flex;justify-content: space-between;line-height: 200%}
.title{font-size: 16px;font-weight: bolder}
/*.taskList{ height: calc(100vh - 240px);overflow: auto}*/
.taskItem{margin-bottom: 5px;background-color: white;padding: 10px 10px}
.level {
  /* min-height: 30px; */
  /* line-height: 50px; */
  margin-bottom: 5px !important;
}
.item-head {
  /* min-height: 30px; */
  padding: 15px 0;
}
.item-body {
  /* height: 70px; */
  /* line-height: 70px; */
  padding: 20px 0;
}
.item-h .van-col {
  display: flex;
  align-items: center;
}
</style>

<style scoped lang="css" src="./assets/index.css" />
