<template>
  <div class="main">
    <div class="header">
      <div style="height: 15px"></div>
      <h3>零售药店全场景智控系统</h3>
      <!-- <van-row  type="flex" justify="left" align="center">
        <van-col span="18" ><h3>零售药店全场景智控系统</h3></van-col>
      </van-row> -->
      
      <div class="jds" v-if="userType == '50'">{{storeName}}</div>
      <div class="jcy" v-if="userType == '40'">
        <van-row  type="flex" justify="left">
          <van-col span="23" >
            <div class="name">{{name}} <span class="jc">检查员</span></div>
          </van-col>
        </van-row>
      </div>
      <div style="height: 30px"></div>
      <div class="news">
        <van-row >
        <van-col span="4" ><div class="title">最新通知</div></van-col>
          <van-col span="1">|</van-col>
        <van-col v-if="notice"><div class="content" @click="goNotice(notice.id)">{{notice.title}}</div></van-col>
      </van-row></div>
    </div>
    <div>
      <div v-for="item in listData" :key="item.title" class="main-box-item">
        <div class="title-text">{{ item.title }}</div>
        <van-row>
          <van-col v-for="data in item.children" :key="data.title" span="6" @click="$router.push(data.path)">
            <template v-if="showItem(data)">
              <div class="icon-box">
                <div :class="['main-box-icon', data.class]">
                  <van-image
                    width="20"
                    height="20"
                    :src="data.src"
                  />
                </div>
              </div>
              <div class="text">{{ data.title }}</div>
            </template>
          </van-col>
        </van-row>
      </div>
    </div>
    <p style="font-size: 12px;">如使用过程有问题可联系 - 13757975858</p>
    <div style="height: 60px"></div>
    <div class="footer_menu">
      <van-tabbar v-model="active">
        <van-tabbar-item icon="home-o">首页</van-tabbar-item>
        <van-tabbar-item icon="friends-o" @click="openMine">我的</van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>
<script>
import { getTask,getUser,getType,getStatus,getNotice, storeLevelListApi, getLogout } from '@/api/get'
import Vue from 'vue';
import {Button, col, row, Tabbar, TabbarItem,Toast, Field, Image as VanImage } from 'vant'
import {taskAll} from "../../api/get";
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Field)
Vue.use(Toast);
Vue.use(VanImage)
export default {
  components:{
    [Button.name]: Button,
    'van-col': col,
    'van-row': row,
  },
  data() {
    return {
      userData:{},
      AllTask:[],
      arrType:[],
      name:'',
      storeName:'',
      userType:'',
      listType: 1,
      active: 0,
      notice:[],
      tasklist:[],
      msg: [],
      storeLevelOption: [],
      keyword: '',
      listData: [
        {
          title: '检查任务',
          children: [
            {
              title: '今日检查',
              class: 'main-box-icon-1',
              path: '/today',
              src: require('../../assets/icons/today_check.png')
            },
            {
              title: '历史检查',
              class: 'main-box-icon-2',
              path: '/history',
              src: require('../../assets/icons/history_check.png')
            }
          ]
        },
        {
          title: '监管档案',
          children: [
            {
              title: '监管档案',
              class: 'main-box-icon-3',
              path: '/archives',
              src: require('../../assets/icons/regulatory_archives.png')
            },
            {
              title: '监管所任务',
              class: 'main-box-icon-2',
              path: '/task',
              type: 20,
              src: require('../../assets/icons/regulatory_task.png')
            }
          ]
        },
        {
          title: '审核管理',
          children: [
            {
              title: '责令整改',
              class: 'main-box-icon-1',
              path: '/disposal',
              src: require('../../assets/icons/disposal_audit.png')
            }
          ]
        }
      ]
    };
  },
  created() {
    if (!localStorage.Authorization) {
      this.$router.push({ path: '/login', query: {id:1}})
    }
    this.id=this.$route.query.id
    if(this.id==1){
      this.listType=2
    }
    this.userType=localStorage.userType
    this.user()
  },
  mounted() {
    // this.queue(this.$route.name,this.$route.meta.title,this.$route.path)
  },
  methods: {
    async taskAll(){
      let rs=await taskAll()
      if(rs.data.code==200){
        this.AllTask=rs.data.data.list
      }
    },
    ctype(t){
      return getType(t)
    },
    cstatus(t){
      return getStatus(t)
    },
    showItem(item) {
      if (!item.type) {
        return true
      }
      if(item.type == JSON.parse(localStorage.getItem('userData')).officeType) {
        return true
      }
      return false
    },
    user(){
        getUser().then(res => {
        if (res.data.code === 200){
          this.userData=res.data.data
          //this.userData.officeType=20
          let userData=JSON.stringify(res.data.data)
         // console.log(JSON.parse(userData))
          this.name = res.data.data.nickName;
          this.storeName = res.data.data.storeName;
          localStorage.storeId = res.data.data.storeId;
          localStorage.userId = res.data.data.id
          localStorage.storeName = res.data.data.storeName;
          localStorage.name = res.data.data.nickName;
          // console.log(res.data.data.userType)
          this.userType = res.data.data.userType != null ? JSON.parse(res.data.data.userType)[0] : 40
          localStorage.userType = this.userType
          // console.log(res.data.data.userType)
          localStorage.userData = userData;
          this.noticeList()
          this.taskList()
          // this.SetZwUserAplus()
        }else{
          // this.$router.go(0)
        }
      })
    },
    goNotice(id){
      this.$router.push({ path: '/notice', query: {id:id}})
    },
    noticeList(){
      let post={
        page:1,
        count:1
      }
      getNotice(post).then(res => {
        if (res.data.code === 200){
        this.notice=res.data.data.list[0]
        }
      }).catch(() => {
        // Toast('err' + JSON.stringify(res))
      })
    },
    taskList(){
      // Toast('taskList')
      let today=1
      let status=10
      if(this.listType==1){
        today=1
      }else{
        today=0
        status=30
      }
      if(this.userType==40&&this.listType==1){
        status=20
      }
      let post={
        page:1,
        count:100,
        isToday:today,
        status:status
      }
      this.tasklist = []
      getTask(post).then(res => {
        if (res.data.code === 200){
          this.tasklist=res.data.data.list
        }
      })
    },
    loadStoreLevel() {
      storeLevelListApi({}).then(res => {
        this.storeLevelOption = res.data.data
      })
    },
    handleSearchLevel() {
      this.storeLevelOption = this.storeLevelOption.filter(x => x.storeName.indexOf(this.keyword) > -1)
    },
    openInspector(){
      this.$router.push({ path: '/inspector', query: {}})
    },
    openMine(){
      this.$router.push({ path: '/mine', query: {}})
    },
    detail(id){
      this.$router.push({ path: '/detail', query: {id:id}})
    },
    history(t) {
      this.listType=t
      if(t==4){
        this.taskAll()
        return
      }
      if (t != 3) {
        this.taskList()
      } else {
        this.loadStoreLevel()
      }
    },
    xiafa(id) {
      this.$router.push({ path: '/xiafa', query: {id:id}})
    },
    begin(id,regulatoryTaskId) {
      this.$router.push({ path: '/begin', query: {id:id,taskId:regulatoryTaskId}})
    },
    logout(){
      Toast('正在退出...')
      getLogout().then(res => {
        if (res.data.code === 200){
          localStorage.clear()
          this.$router.push({ path: '/login', query: {id:1}})
        }else{
          Toast.fail(res.data.msg)
        }
      }).catch(() => {
        localStorage.clear()
        this.$router.push({ path: '/login', query: {id:1}})
      })
    },
    getLevel(score) {
      if (score >= 80) {
        return '<span style="color: #07C160">绿码</span>'
      } else if (score >= 60) {
        return '<span style="color: #FAAB0C">黄码</span>'
      } else if (score >= 0) {
        return '<span style="color: #EE0A24">红码</span>'
      } else {
        return '<span style="color: #646566">灰码</span>'
      }
    },
    getRisk(score) {
      if (score >= 85) {
        return 'A'
      } else if (score >= 70) {
        return 'B'
      } else if (score >= 60) {
        return 'C'
      } else {
        return 'D'
      }
    }
  }
};
</script>

<style scoped>
.row{display: flex;justify-content: space-between;line-height: 200%}
.title{font-size: 16px;font-weight: bolder}
/*.taskList{ height: calc(100vh - 240px);overflow: auto}*/
.taskItem{margin-bottom: 5px;background-color: white;padding: 10px 10px}
.level {
  /* min-height: 30px; */
  /* line-height: 50px; */
  margin-bottom: 5px !important;
}
.item-head {
  /* min-height: 30px; */
  padding: 15px 0;
}
.item-body {
  /* height: 70px; */
  /* line-height: 70px; */
  padding: 20px 0;
}
.item-h .van-col {
  display: flex;
  align-items: center;
  
}
.main-box-item {
  
  background-color: #ffffff;
  margin: 7px 7px;
  padding: 12px 5px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}
.main-box-item .title-text {
  text-align: left;
  text-indent: 10px;
  font-size: 14px;
}
.main-box-item .van-col {

  margin: 10px 0;
}
.main-box-item .van-col .text {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Microsoft YaHei" !important;
}
.icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-box-item .van-image {
  margin-top: 15px;
}
.main-box-icon {
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.main-box-icon-1 {
  background-image: url(../../assets/icons/bg_1.png);
}
.main-box-icon-2 {
  background-image: url(../../assets/icons/bg_2.png);
}
.main-box-icon-3 {
  background-image: url(../../assets/icons/bg_3.png);
}
</style>

<style scoped lang="css" src="./assets/index.css" />
