<template>
  <div class="main">
    <!-- -->
    <van-nav-bar
      title="责令整改审核"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="detail">
      <p><span>店&emsp;&emsp;铺：</span>{{ detailData.storeName }} 
        <van-tag v-if="detailData.status == 40" type="success" size="large">审核通过</van-tag>
        <van-tag v-if="detailData.status == 10 && detailData.reason != '' && detailData.reason != null" type="danger" size="large">审核不通过</van-tag>
      </p>
      <p><span>标&emsp;&emsp;题：</span>{{ detailData.title }}</p>
      <p><span>预警内容：</span>{{ detailData.content }}</p>
      <p><span>处理时间：</span>{{ detailData.handleTime }}</p>
      <p><span>处&ensp;理&ensp;人：</span>{{ detailData.handleName }}</p>
      <p>
        <span>回复内容：</span>
        <div v-if="detailData.repayContent != '' && detailData.repayContent != null">
          <div v-if="detailData.repayContent.indexOf('orderInfo') == -1" style="text-align: left;">{{ detailData.repayContent }}</div>
          <div v-if="detailData.type == 41 && detailData.repayContent.indexOf('orderInfo') != -1">
            <div style="text-align: left;">{{ JSON.parse(detailData.repayContent).content }}</div>
            <div v-for="item in replenishOrderInfo" :key="item.field" style="text-align: left;margin-top: 5px;">
              {{ item.label }}: {{ getHideData(detailData.repayContent, item.field) }}
            </div>
          </div>
        </div>
      </p>
      <p><span>回复图片：</span></p>  
      <p v-if="detailData.repayPic" class="detail-image">
        <van-image v-for="(item, index) in JSON.parse(detailData.repayPic)" :key="index" width="100" height="100" :src="item" @click="handlePreview" />
      </p>
    </div>
    <div class="bottom-box">
      <van-row v-if="detailData.status === 30">
        <!-- <van-col :span="8">
          <van-button type="info">订单详情</van-button>
        </van-col> -->
        <template>
          <van-col :span="12">
            <van-button type="primary" size="large" @click="handlePass">审核通过</van-button>
          </van-col>
          <van-col :span="12">
            <van-button type="danger" size="large" @click="handleReject">审核拒绝</van-button>
          </van-col>
        </template>
      </van-row>
    </div>
    <van-dialog v-model="show" title="审核拒绝" show-cancel-button :closeOnPopstate="false" :before-close="handleBeforeClose" @confirm="handleConfirm">
      <van-field v-model="rejectReason" label="拒绝理由" rows="5" type="textarea" placeholder="请输入拒绝理由" />
    </van-dialog>
  </div>
</template>

<script>
import { disposalDetailApi, disposalPassApi, disposalRejectApi } from '@/api/get'

import Vue from 'vue';
import { NavBar, Field, Button, Image, ImagePreview, Col, Row, Toast, Dialog, Tag } from 'vant'
Vue.use(NavBar)
Vue.use(Field)
Vue.use(Button)
Vue.use(Image)
Vue.use(Col);
Vue.use(Row);
Vue.use(Toast)
Vue.use(Dialog);
Vue.use(Tag)
export default {
  name: 'RegulatoryH5DisposalDetail',

  data() {
    return {
      detailData: {
        type: 0,
        storeName: '',
        title: '',
        content: '',
        createDate: '',
        handleTime: '',
        handleName: '',
        completeTime: '',
        completeName: '',
        pic: "",
        reason: '',
        repayContent: '',
        repayPic: ''
      },
      replenishOrderInfo: [
        {
          field: 'patientName',
          label: '患者姓名'
        },
        {
          field: 'patientIdcard',
          label: '患者身份证号码'
        },
        {
          field: 'patientPhone',
          label: '患者联系方式'
        },
        {
          field: 'prescriptionDoctor',
          label: '处方医生'
        },
        {
          field: 'prescriptionPharmacist',
          label: '审方执业药师'
        }
      ],
      show: false,
      rejectReason: ''
    };
  },
  created() {
    this.loadDetailData()
  },
  methods: {
    onClickLeft() {
      this.$router.push('/disposal')
    },
    loadDetailData() {
      disposalDetailApi({ id: this.$route.params.id }).then(res => {
        this.detailData = res.data.data
      })
    },
    handlePreview() {
      ImagePreview(JSON.parse(this.detailData.repayPic));
    },
    handlePass() {
      disposalPassApi({ id: this.detailData.id }).then(res => {
        if (res.data.code === 200) {
          this.loadDetailData()
          Toast.success('审核成功')
        } else {
          Toast.fail(res.data.msg)
        }
      })
    },
    handleReject() {
      this.show = true
    },
    handleConfirm() {
      if (this.rejectReason === '') {
        return
      }
      disposalRejectApi({ id: this.detailData.id, reason: this.rejectReason }).then(res => {
        if (res.data.code === 200) {
          this.loadDetailData()
          Toast.success('审核成功')
        } else {
          Toast.fail(res.data.msg)
        }
      })
    },
    handleBeforeClose(action, done) {
      if (this.rejectReason === '' && action === 'confirm') {
        Toast.fail('请输入拒绝理由')
        done(false)
      } else {
        done()
      }
    },
    getHideData(repayContent, field) {
      try {
        if (field === 'patientName' || field === 'prescriptionDoctor' || field === 'prescriptionPharmacist') {
          return this.trueNameHide(JSON.parse(repayContent).orderInfo[field])
        } else if (field === 'patientIdcard') {
          return this.idCardHide(JSON.parse(repayContent).orderInfo[field])
        } else if (field === 'patientPhone') {
          return this.phoneHide(JSON.parse(repayContent).orderInfo[field])
        }
      } catch (err) {
        console.error('disposal getHideData', err)
      }
      return ''
    },
    phoneHide(phone) {
      return phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
    },
    idCardHide(idCard) {
      return idCard.replace(/(\d{6})\d{8}(\d{4})/, '$1********$2');
    },
    trueNameHide(trueName) {
      return trueName.replace(/(.).*/, '$1**');
    },
    addressHide(str) {
      return str.replace(/(.{9})(.*)/, '$1**');
    }
  },
};
</script>

<style scoped>
.detail {
  position: fixed;
  top: 50px;
  bottom: 50px;
  margin: 10px;
  overflow-y: auto;
}
.detail p {
  font-size: 14px;
  text-align: left;
  line-height: 1.6;
}
.detail p span {
  font-weight: bold;
}
.detail-image {
  margin-left: 30px;
}
.bottom-box {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.van-image {
  margin: 5px;
  border: 3px solid #999;
}
</style>
