<template>
  <div class="main">
    <van-image :src="url" width="270" height="270" />
    <!-- <p class="tip">抱歉，您暂无当前页面的访问权限，请联系管理员</p> -->
    <!-- <p class="tip">网络错误，请检查网络连接是否正常</p> -->
    <p class="tip">暂无相关内容，请返回上一层</p>
    <br>
    <p class="qt">应用归属单位<span>金华市市场监管局</span></p>
    <br>
    <p class="qt">应用管理员<span>邓金龙</span></p>
    <br>
    <p class="qt">联系方式<span>13646791871</span></p>
  </div>
</template>

<script>
import Vue from 'vue';
import { Image as VanImage } from 'vant';

Vue.use(VanImage);
export default {
  name: 'RegulatoryH5TIP',

  data() {
    return {
      url: require('../assets/tip.png')
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>
<!--  -->
<style scoped>
.main {
  padding: 10px 30px;
}
.tip {
  /* width: 60%; */
  padding: 10px 68px;
  text-align: center;
}
.qt {
  text-align: left;
}
.qt span {
  float: right;
}
</style>