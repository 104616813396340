<template>
  <div class="main">
    <!-- -->
    <van-nav-bar
      title="监管档案"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div v-if="storeLevelOption && storeLevelOption.length === 0">
      <van-empty description="暂无数据" />
    </div>
    <div v-else class="list">
      <van-field
        placeholder="请输入企业名称查询"
        v-model="keyword"
        left-icon="search"
        clearable
        @input="handleSearchLevel"
      />
      <div class="item level item-head">
        <van-row>
          <van-col span="12">企业名称</van-col>
          <van-col span="6">风险等级</van-col>
          <van-col span="6">信用等级</van-col>
        </van-row>
      </div>
      <div v-for="item in storeLevelOption" :key="item.id" class="item level item-body">
        <van-row>
          <van-col span="12" class="van-multi-ellipsis--l2">{{ item.storeName }}</van-col>
          <van-col span="6" v-html="getLevel(item.score)"></van-col>
          <van-col span="6">{{ getRisk(item.score) }}</van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
import {  storeLevelListApi} from '@/api/get'
import Vue from 'vue';
import { NavBar, Button, col, row, Field, Image, Empty } from 'vant'
Vue.use(NavBar)
Vue.use(Button)
Vue.use(Field)
Vue.use(Image)
Vue.use(Empty)
export default {
  name: 'RegulatoryH5Archives',
  components:{
    [Button.name]: Button,
    'van-col': col,
    'van-row': row,
  },
  data() {
    return {
      storeLevelOption: [],
      keyword: ''
    };
  },
  created() {
    this.loadStoreLevel()
  },

  methods: {
    loadStoreLevel() {
      this.storeLevelOption = null
      storeLevelListApi({}).then(res => {
        this.storeLevelOption = res.data.data
      })
    },
    onClickLeft() {
      this.$router.push('/')
    },
    handleSearchLevel() {
      this.storeLevelOption = this.storeLevelOption.filter(x => x.storeName.indexOf(this.keyword) > -1)
    },
    getLevel(score) {
      if (score >= 80) {
        return '<span style="color: #07C160">绿码</span>'
      } else if (score >= 60) {
        return '<span style="color: #FAAB0C">黄码</span>'
      } else if (score >= 0) {
        return '<span style="color: #EE0A24">红码</span>'
      } else {
        return '<span style="color: #646566">灰码</span>'
      }
    },
    getRisk(score) {
      if (score >= 85) {
        return 'A'
      } else if (score >= 70) {
        return 'B'
      } else if (score >= 60) {
        return 'C'
      } else {
        return 'D'
      }
    }
  },
};
</script>


<style scoped>
.row{display: flex;justify-content: space-between;line-height: 200%}
.title{font-size: 16px;font-weight: bolder}
/*.taskList{ height: calc(100vh - 240px);overflow: auto}*/
.taskItem{margin-bottom: 5px;background-color: white;padding: 10px 10px}
.level {
  /* min-height: 30px; */
  /* line-height: 50px; */
  margin-bottom: 5px !important;
}
.item-head {
  /* min-height: 30px; */
  padding: 15px 0;
}
.item-body {
  /* height: 70px; */
  /* line-height: 70px; */
  padding: 20px 0;
}
.item-h .van-col {
  display: flex;
  align-items: center;
}
</style>

<style scoped lang="css" src="./assets/index.css" />
