<template>
  <div class="main">
    <!-- -->
    <van-nav-bar
      title="责令整改审核"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="list">
      <van-search v-model="searchKeyword" show-action placeholder="请输入店铺名称搜索">
        <template #action>
          <van-button type="primary" size="normal" @click="onSearch">搜索</van-button>
        </template>
      </van-search>
      <van-list v-if="dataList && dataList.length > 0">
        <div v-for="item in dataList" :key="item.id" class="item">
          <p>店铺：{{ item.storeName }}</p>
          <p>标题：{{ item.title }}</p>
          <p>时间：{{ item.createDate }}</p>
          <van-button type="warning" size="normal" round class="audit-btn" @click="$router.push('/disposal/' + item.id)">去审核</van-button>
        </div>
      </van-list>
      <div v-else>
        <van-empty description="暂无数据" />
      </div>
    </div>
  </div>
</template>

<script>
import { disposalListApi } from '@/api/get'

import Vue from 'vue';
import { NavBar, Field, Search, Button, List, Image, Empty } from 'vant'
Vue.use(NavBar)
Vue.use(Field)
Vue.use(Search)
Vue.use(Button)
Vue.use(List)
Vue.use(Image)
Vue.use(Empty)
export default {
  name: 'RegulatoryH5Disposal',

  data() {
    return {
      searchKeyword: '',
      dataList: []
    };
  },

  created() {
    this.loadDisposalListApi()
  },

  methods: {
    onClickLeft() {
      this.$router.push('/')
    },
    loadDisposalListApi() {
      this.dataList = null
      disposalListApi({ storeName: this.searchKeyword, page: 1, pageSize: 100 }).then(res => {
        this.dataList = res.data.data.list
      })
    },
    onSearch() {
      this.loadDisposalListApi()
    }
  },
};
</script>

<style scoped>

.item {
  margin: 10px 5px;
  padding: 10px;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}
.item p {
  text-align: left;
  font-size: 14px;
}
.audit-btn {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
</style>

<style scoped lang="css" src="./assets/index.css" />
